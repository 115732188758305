.title  {
    width: 100%;
    height: 55px;
}
#write_article_div{
    margin: 15px;
}
#description{
    width: 100%;
    height: 55px;
}
#slug{
    width: 100%;
    height: 35px;
    
}
#save{
    width: 100%;
    height: 55px;
    background-color:beige;
    cursor: pointer;

}
#image {
    width: 100%;
    height: 35px; 
}
#category{
align-content: left;
margin-left: 0px;
float: left;
height: 32px;
padding-left: 0px;
}
#submitToReview{
    width: 100%;
    height: 55px;
    border-color: pink;
    margin: 1px;
    background-color: rgb(81, 246, 119) !important;
    border-width: 2px;
    border-style: solid double solid double;

    cursor: pointer;

}