.cryptoClass{
    color:#EEEEEE;
}
.lastUpdated{
    color: rgb(9, 255, 0) !important;
}



input[type=range] {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 25px; /* Specified height */
    background: linear-gradient(to right, red , yellow, green); /* Background gradient */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (optional) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
}

/* Slider handle styles for Webkit browsers */
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #fff; /* Slider handle color */
    cursor: pointer; /* Cursor on hover */
    border: 2px solid #ddd; /* Handle border */
    border-radius: 50%; /* Round edges */
}

/* Slider handle styles for Mozilla browsers */
input[type=range]::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #fff; /* Slider handle color */
    cursor: pointer; /* Cursor on hover */
    border: 2px solid #ddd; /* Handle border */
    border-radius: 50%; /* Round edges */
}
.postive_score{
    color: rgb(46, 149, 46);
}
.negative_score{
    color: rgb(240, 77, 62);
}
.neutral_score{
    color: rgb(240, 219, 62);
}

.fear-greed-container {
    width: 100%;
    background-color: #eee;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.fear-greed-bar {
    height: 30px;
    border-radius: 10px 0 0 10px;
    transition: width 0.5s ease-in-out;
}

.fear-greed-label {
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
}


.article {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.progress-container {
    background-color: #ddd;
    border-radius: 8px;
    margin-top: 10px;
}

.progress-bar {
    height: 20px;
    border-radius: 8px;
    background: linear-gradient(to right, red, yellow 50%, green);
    transition: width 0.5s ease-in-out;
}
.readArticle{
    background-color: darkgray;
    border-radius: 7px;
    color: whitesmoke;
    padding: 7px;
}




.range-container {
    width: 300px;
    margin: auto;
}

.range-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.sentiment-range {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    background: linear-gradient(to right, rgb(88, 87, 87) 0%, black 100%) !important;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    margin-bottom: 20px;
}

.sentiment-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #fff;
    cursor: pointer;
    border: 2px solid #ddd;
    border-radius: 50%;
}

.sentiment-range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #fff;
    cursor: pointer;
    border: 2px solid #ddd;
    border-radius: 50%;
}





.pieContainer {
    height: 150px;
    position: relative;
}

.pieBackground {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
}

.pie {
    transition: all 1s;
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    clip: rect(0px, 75px, 150px, 0px);
}

.hold {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    clip: rect(0px, 150px, 150px, 75px);
}

#pieSlice1 .pie {
    background-color: #008000; /* Green for Positive */
     /* Adjusted for 1/3 of the pie */
}

#pieSlice2 {
    transform: rotate(120deg); /* Start the second slice */
}

#pieSlice2 .pie {
    background-color: #FFFF00; /* Yellow for Neutral */
    transform: rotate(120deg); /* Another 1/3 of the pie */
}

#pieSlice3 {
    transform: rotate(240deg); /* Start the third slice */
}

#pieSlice3 .pie {
    background-color: #FF0000; /* Red for Negative */
    transform: rotate(120deg); /* Final 1/3 of the pie */
}

.innerCircle {
    position: absolute;
    width: 120px;
    height: 120px;
    background-color: #444;
    border-radius: 100%;
    top: 15px;
    left: 15px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
    color: white;
}
.innerCircle .content {
    position: absolute;
    display: block;
    width: 120px;
    top: 30px;
    left: 0;
    text-align: center;
    font-size: 14px;
}



.neuKeyword{
  color: #FFFF00;  
}
.negKeyword{
    color:red;  
  }
  .posKeyword{
    color: green;  
  }

.greyBG{
    background-color: #eee;
}
.whiteSmoke{
    color: whitesmoke;
}