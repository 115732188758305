.imgCourse{
    max-width: 100%
}
.course_nodes {
    opacity: .66;
    padding: 5px;
}
.course_image, .course_nodes {
    background-color: #434343!important;
    border-color: gray;
    border-style: solid double;
    border-width: 1px;
}
.course_element_title {
    color: #eee;
    font-size: 27px;
    font-weight: 800;
    opacity: .94;
    text-align: left;
}


.learn_list{
    list-style-type: none;
    color: azure;
    font-size: 19px;
    text-align: left;
    list-style-image: url('../img/check-mark_16.png') !important;

  }

  .accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
  .active, .accordion:hover {
    background-color: #ccc; 
  }
  
  .panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
  }