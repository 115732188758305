button{
    all: unset;

}
/**
 * http://applemusic.tumblr.com/
 */

/** Ultra Light */

  
  /** Thin */

  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap'); 
  * {
    font-family: 'Lato', sans-serif;  }
  
  h1 { font-weight: 700; }
  h2 { font-weight: 600; }
  h3 { font-weight: 500; }
  p { font-weight: 400; }
  p.thin { font-weight: 200; }
  p.ultralight { font-weight: 100; }
#header{
    position: absolute;
    height: 72px !important;
    background-color: rgb(12, 11, 11);
    width: 100%;
    color: aliceblue;
    padding-bottom: 0px;
    border-bottom: 1px solid #12DFEE;

}

#menu_button{

    background-color: rgb(23, 23, 23);
    width: 133px;
    transition: all .33s ease-in-out;
z-index: 1000;
}
#menu_button:hover{
    cursor: pointer;

}
.nav_animate_text{
    position: absolute;
    left: 10px;
    height: 80px;
width: 130px;
    top: 36px;
    margin-top: -2px;
    font-size: 15px;
    font-weight: 600;
    color: #bababa;
transform: translateY(-50%);
-ms-transform: translateY(-50%);
-moz-transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-o-transform: translateY(-50%);
transition: all .666s ease-in-out;
z-index: 9999;

}

.nav_animate_text:hover{
transition: all .666s ease-in-out;
color: #666 ;

cursor: pointer;

}
.icons-menu{
    left: 10px;
    margin-left: 21px;
}
.icons-menu .bar:nth-child(1) {
    top: 25px;

    width: 18px;
    margin-left: 0px;
height: 4px;
    color: white;
    background: #2ad8ff; /* default color */

    /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to left, #01CEFF 10%, #bababa 50%) right;
    background: linear-gradient(to left, #01CEFF 10%, #bababa 50%) right;

    background-size: 200%;  
    transition: .5s ease-out;
}
.icons-menu .bar:nth-child(2) {


    color: #12DFEE;
    background: #12DFEE; /* default color */

    /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to left, #12DFEE 50%, #bababa 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    opacity: 1;
    height: 4px;

margin-left: 0px;
    top: 29px;
    width: 12px;
}
/*.icons-menu .bar:nth-child(3) {
    transition-delay: 1.2s;
    color: white;
    background: #01CEFF; /* default color */

    /* "to left" / "to right" - affects initial color 
    background: linear-gradient(to right, #01CEFF 50%, lightblue 50%) left;
    background-size: 200%;
    transition: .5s ease-out;

    top: 34px;
    width: 3px
    margin-left: 3px
    background-color: #23EEDD;
}*/
.icons-menu .bar:nth-child(4) {
    color: #23EEDD;
    background: #23EEDD; /* default color */

    /* "to left" / "to right" - affects initial color */
    background: linear-gradient(to left, #23EEDD 50%, #bababa 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
    opacity: 1;
    height: 4px;

    top: 34px;
    width: 12px;
    margin-left: 0px;
    background-color: #01CEFF;
}
.icons-menu .bar:nth-child(5) {

        color: #23EEDD;
    background: #23EEDD; /* default color */
height: 4px;    
    /* "to left" / "to right" - affects initial color */

    background: linear-gradient(to left,#DC1FFF  10%, #23EEDD 50%) right;
    background-size: 200%;
    transition: .5s ease-out;

    top: 38px;
    width: 18px;
    margin-left: 0px;
    background-color: #DC1FFF;
}
.icons-menu .bar {
    position: absolute;
    height: 5px;
    overflow: hidden;
}
#menu_button{
    width: 128px;
    height: 72px;
    border-right: 1px solid #12DFEE;
    border-bottom: 1px solid #12DFEE;
    border-top: 1px solid #12DFEE;
    border-left: 0px solid #12DFEE;


}
#menu_button:hover{
    cursor: pointer;
    transition: all .666s ease-in-out;
    background-size: 200% 100%;
    
}

#menu_button:hover .bar:nth-child(1) {

    -webkit-transition: .9s;
    transition: .9s;
    background-position: left;
    

}

#menu_button:hover .bar:nth-child(2) {
    background-position: left;
}
/*#menu_button:hover .bar:nth-child(3) {
    background-position: right;
    transition-delay: .7s;

}*/
#menu_button:hover .bar:nth-child(4) {
    background-position: left;
}
#menu_button:hover .bar:nth-child(5) {
    background-position: left;

}



#menu_button .bar{
    -webkit-transition: .7s;
    transition: .9s;
background-size: 200% 100%;

}
#img_logo{
    position: absolute;
    height: 33px;
    top: 50%;
transform: translateY(-50%);
-ms-transform: translateY(-50%);
-moz-transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-o-transform: translateY(-50%);
margin-left: -24px;

}
#img_logo:hover{
    cursor: pointer;
}

#bt-learn button {
    height: 69px !important;
    top: 0px;
    right: 0px;
    transition: all .3s ease-in-out;
    position: absolute;
    font-size: 16px;
    color: aliceblue;
    font-weight: 600;
    width: 180px;
    background-color: #01CEFF;
    border: #4be9ee;
}
#bt-learn button:hover{
    background-color: #12DFEE;
    cursor: pointer;
}
#register_login{
    color: #48FFFF;
}
@media only screen and (max-width: 600px){

   #bt-learn button{
    display: none;
   } 
}
#brand_name{
    margin-top: -37px;
    margin-left: auto;
    padding-left: 150px;
    margin-right: auto;


    font-size: 14px;
    font-weight: 600;
transform: translateY(-50%);
-ms-transform: translateY(-50%);
-moz-transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-o-transform: translateY(-50%);
color: whitesmoke;
margin-left: -17px;
}
#brand_name:hover{
    cursor: pointer;
    color: #bababa;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all .33s ease-in-out;


}
.buttonSU{
    height: 69px !important;
}

.right:hover{
    cursor: pointer;
}

#coins{
    margin-top: -4px;
    
    background-color: rgb(12, 11, 11);
    color: darkgoldenrod !important;
}
.coins{
    align-content: right;
    margin-right: 0px;
    color: gold !important;
    font-size: 23px;
    position: relative;
    margin-right: 12px;

}
.alert {
    margin-top: -25px;
    padding: 20px;
    background-color: #bdebc8 !important;
    color: rgb(52, 52, 52) !important;
    cursor: pointer;
  }