#sub_menu li{
    all: none;
    list-style: none;
    z-index: 3;
    text-align: start;

}
#sub_menu {
    position: relative;
    top: -20px;
    left: -39px;
    width: 270px;
    
    position: relative;
    background-color: #17c7f3;

    color: white;

}
#sub_menu li{
    padding: auto;
    padding: 6px;
    
    padding-left: 12px;
    margin-left: 10px;
    font-size: larger;
    font-weight: 800;

} 
#sub_menu li:hover{
    cursor: pointer;
}
.menuhr {
    height: 4px;
    width: 100%;
padding: 0px !important;
margin: auto;
margin-top: 15px !important;
    margin-left: -2px !important;
    background-color: #000;
}
#mainNav{
    background-color: #01CEFF;
    width: 270px;
    position: fixed;
    overflow-y: auto;
}
#sub_menu_top {
    position: relative;
left: 0px;
border-bottom: #444 solid 1px;
border-top: #444 solid 1px;

z-index: 3000;
width: 271px;
height: 72px;
background-color: #01CEFF;
}
#sub_menu_top .left{
    float: left;
    padding: 12px;
}
#sub_menu_top .left:hover{
    cursor: pointer;
}
#sub_menu_top .right1{
    all: none;
    border: none;
    float: right;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    right: 23px;
    color: aliceblue;
    background-color: #01CEFF;

    padding: 23px;
}
#sub_menu_top .right1:hover{
    float: right;
    padding: 23px;
    cursor: pointer;
}

#sub_menu_top .right2{
    all: none;
    border: none;
    float: right;
    right: 0px;
    position: absolute;
    margin-top: 6px;
    margin-right: 3px;
    font-size: 18px;
    font-weight: 500;
    color: aliceblue;
    background-color: #01CEFF;

    padding: 13px;
}
#sub_menu_top .right2:hover{
    float: right;
    padding: 13px;
    cursor: pointer;
}

.show_nav > a{
    all: unset;
}
.shownav > ul > li {
    font-weight: bold;
    font-size: 22px;

}
.english{
    color: #323232;
}
.show_nav{
    text-align: left;
    background-color: #0fbefd !important;
    margin-top: auto !important;
    transition: .666s ease-in;
    opacity: 1;
    transition: .83s ease-in-out;
    margin-left: 0px;
    top: 0px !important;
    margin-top: 0px !important;
    height: 100%;
}
.close{
    height: 17px;
    padding-top: 7px;
}
.hide_nav{
    opacity: .83;
    margin-top: 0px !important;
    transition: .83s ease-in;
    top: 0px !important;
    height: 100%;

    margin-left: -270px; 
    background-color: #181717 !important;
}
.header_static{
    opacity: 1;
    transition: .97s ease-in-out;
    margin-left: 0px;
}
.header_move{ 
    opacity: .23;
    transition: .6s ease-in;
    margin-left: 270px !important;
    background-color: #161616 !important;
}
.main_visible{
opacity: .83;
pointer-events: none;
overflow-y: hidden;
overflow-x: hidden;
background-color: #181717 !important;
height: 900px;
position: absolute;
margin: 0;
width: 100%;
}

.main_invisible{
    opacity: 1;
}
html, body{
    overflow-x: hidden;

}
/* .show_main{
transition: .66s ease;
margin-left:0px;

}
.hide_main{
    transition: .66s ease;
    margin-left: -270px;

} */
@media only screen and (max-width: 600px) {
   
}

#sub_menu_bottom{
    position: absolute;
    bottom: 30px;
    left: 20px;
    color: azure;
    font-size: 23px;
    margin: auto;
}
#sub_menu_earn{
    position: absolute;
    bottom: 30px;
    right: 20px;
    color: azure;
    font-size: 23px;
    margin: auto;

}
.language{
    color: whitesmoke;
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #707070;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 5px #707070;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
.active{
    background-color: #323232 !important;
    margin-top: auto !important;
    padding: 10px !important;
    margin-left: -19px !important;
    transition: .666s ease-in;



}
.inactive{
    background-color: #0fbefd !important;
    margin-top: auto !important;
    padding: 10px !important;
    margin-left: -19px !important;
    transition: .666s ease-in;

}
.nested{
    all: unset;
    margin: auto;
    
    margin-left: 30px;
    height: 100%;
    width: 82%;
    position: relative;
    pointer-events: none;
}

.active:hover, .inactive:hover{
    background-color: #595959 !important;
    transition: .333s;

}
.neoNavBtn{
}
.linkA{
  background-color: #17c7f3;
  width: 100%;
  height: 49px;
  margin-bottom: -18px;
  cursor: pointer;
  transition: .666s ease-in;
}
.linkA:hover{
    background-color: #333333;
  width: 100%;
  height: 49px;
  margin-bottom: -18px;
  cursor: pointer;
  transition: .666s ease-in;
  
}