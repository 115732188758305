.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#brand_name{
  color: aliceblue !important;
}
#myAccount{
  font-weight: 700;
  color: #f1f1f1 !important;
background-color: #999;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.lesson{
  background-color: mediumturquoise !important;
  color: #f1f1f1 !important;
}
.lessonA{
  color: #f1f1f1 !important;
padding-left: 10px;
}
.classTitle, .classTitle:visited, .classTitle:active, .classTitle:link{
  background-color: #282c34 !important;
  color: aqua;
}

.activeL{
  background-color: #636969 !important;
}
a{
  color: #61dafb;
  text-decoration: none;
}

 