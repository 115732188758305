.promptTitle{
    color: #333;
    text-align: left;
}
.promptDescription p{
    text-align: left !important ;
    color: #000;
}

.buybutton {
    cursor: pointer;
bottom: 29px !important;
    background-image: linear-gradient(280deg,#01CEFF,#4be9ee 62%,#aafae2 100%,#fff);
    color: #111 !important;
    font-weight: 600;
    text-decoration: none;
    border: 2px solid #01CEFF;

    font-size: 28px;
    display: inline-flex;
    border-radius: 6px;
    padding: 9px;
    padding-left: 30px;
    padding-right: 30px;
    transition: .66s;
    opacity: .87;
    transition: opacity .3s ease-in-out;
    

}
.buybutton:hover{
opacity: .99;
}
.promptcard{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 2px;
text-align: left;
}
.promptcardtitle{
    width: 100%;
    background-color: #333;
    color: #01CEFF;
    font-weight: 532;
    padding-top: 10px;
    padding-bottom: 12px;
    text-align: left;
}
.promptcardinstruction{
    width: 100%;
    background-color: #666;
    color: #4be9ee;
    text-align: left;
    width: 98.6%;
    
}
.promptcardinstruction::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
.copyprompt{
    font-size: 20px;
    cursor: pointer;
background: #01CEFF;
    color: #000;
    right: 0;
    margin-right: 0;
    padding-left: 90px;
    margin-left: 90px;
    font-weight: 444;
    display: inline-flex;
    border-radius: 6px;
    padding: 9px;
    padding-left: 30px;
    padding-right: 30px;
    transition: .66s;
    opacity: .77;
    transition: opacity .3s ease-in-out;

}
.copyprompt:hover{
    opacity: .976;
}
.bgmaincard{
    background-image: linear-gradient(to right, #111, #222, #333);

}

.inputKeyPrompt{
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    padding: 12px 20px 12px 40px;
    transition: width 0.4s ease-in-out;
}
.inputKeyPrompt::placeholder{
    color: #666;
}


.secureStripe{
    padding: 1px;
    margin: 1px;
    cursor: pointer;
}

.classDescription{
    text-align: left;
}