.articles-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .article-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s ease;
  }
  
  .article-card:hover {
    transform: translateY(-10px);
  }
  
  .article-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .article-content {
    padding: 15px;
  }
  
  .article-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .article-description {
    font-size: 14px;
    margin-bottom: 15px;
    color: #555;
  }
  
  .article-link {
    font-weight: bold;
    color: #1e90ff;
    text-decoration: none;
  }
  
  .article-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .article-card {
      max-width: 100%;
    }
  }
  