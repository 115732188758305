#profile{
    text-align: left;
    padding: auto;
    padding-left: 20px;
    padding-bottom: 250px;
    color: #222;
}
.activeClaim{
    background-color: greenyellow;
    padding: 5px;
    cursor: pointer;
}   
#saveProfile{
padding: 22px;
background-color: rgb(195, 242, 124);
opacity: .666;

}
#claimNFT{
    padding: 22px;
    background-color: rgb(195, 242, 124);
    opacity: .666;
    font-size: 15px;
    cursor:pointer;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    
    }
    #claimNFT:hover{
        font-size: 15px;

        padding: 22px;
        background-color: rgb(201, 201, 85);
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        opacity: .666;
        cursor:pointer;
        
        }
#logoutProfile{
    padding: 22px;
    background-color: #ff8d8d;
    opacity: .666;
}
#saveProfile:hover{
    opacity: 1;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-duration: 1s;
    cursor: pointer;
}
#logoutProfile:hover{
    opacity: 1;
    cursor: pointer;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-duration: 1s;
 
}
.sendProfile{
    padding: 22px;
    background-color: #a5ff6ded;
    opacity: .666;
}
.sendProfile:hover{
    opacity: 1;
    padding: 22px;

    cursor: pointer;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-duration: 1s;
  
}


.hidden{
    display:none !important;
}

