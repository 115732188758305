li{
    all:unset;
}
#signtabs{
    height: 760px;
    width: 90%; margin: 0 auto; text-align: center;
    background-color: #eee;
    
}
body > div {

}
.signbtn{
    padding: 0px;
    width: 50%;
    padding-bottom: 12px;
    background-color: #4be9ee !important;
    cursor: pointer;
    font-size: 19px;
    color: #001;
    background-color: transparent;
    font-family: inherit;
    cursor: pointer;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 15px;
    opacity: .6;
    transition: ease-in-out;

}
.signbtn:hover{
    opacity: .88;
}

.signactive{
    background-color: #01CEFF ;
    color: #001;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
    opacity: 1;
    transition-duration: 500ms;

    transition: ease-in-out;
}
.showsignA{

}
.showsignI{
    display: none;
}
#earn{
    background-color: yellowgreen;
    padding: 10px;
}
.bought{
    background-color: #01CEFF;
    padding: 10px;
    padding: 3px;

}
.inputSign{

   height: 33px;
   padding: 9px;
    width: 300px;  text-align: left;

}
.signactiveTitle{
    color: #001;
}
.btnsign{
    height: 53px;
    padding: 12px !important;
     width: 299px;  text-align: left;
     cursor:pointer;
     background-color: #01CEFF ;
     opacity: 0.9 ;

   
}
.btnsign:hover{
    background-color: #4be9ee;
    opacity: .77;
    transition: ease-in-out;



}
.reset{
    margin-left: 0px;
    padding-left: 0px;
    text-align: center;
    margin: 13px;
    color: black !important;

}
.reset:link, .reset:before, .reset:after, .reset:visited{
    color: black !important;
}

