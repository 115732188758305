.grid-container-3 {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #FAFAFA;
    padding: 10px;
  }
  .grid-container-2 {
    display: grid;
    grid-template-columns: auto auto ;
    background-color: #FAFAFA;
    padding: 10px;
  }
  .grid-container-1 {
    display: grid;
    grid-template-columns: auto;
    background-color: #FAFAFA !important;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    /*border: 1px solid rgba(0, 0, 0, 0.8);*/
    padding: 20px;
    font-size: 20px;
    color: black;
    text-align: center;
  }
  #content{
    background-color: #fff !important;
    height: 90%;
    padding-bottom: 190px !important;
    margin-bottom: 190px !important;


  }
  @media only screen and (max-width: 860px){
    .grid-container-3 {
        display: grid;
        grid-template-columns: auto auto;
        background-color: #FAFAFA;
        padding: 10px;
      }
      .grid-container-2 {
        display: grid;
        grid-template-columns: auto auto;
        background-color: #FAFAFA;
        padding: 10px;
      }
      .grid-container-1 {
        display: grid;
        grid-template-columns: auto ;
        background-color: #2196F3;
        padding: 10px;
      }
  }
  @media only screen and (max-width: 728px){
    .grid-container-3 {
        display: grid;
        grid-template-columns: auto ;
        background-color: #FAFAFA;
        padding: 10px;
      }
      .grid-container-2 {
        display: grid;
        grid-template-columns: auto ;
        background-color: #FAFAFA;
        padding: 10px;
      }
      .grid-container-1 {
        display: grid;
        grid-template-columns: auto ;
        background-color: #FAFAFA;
        padding: 10px;
      }
  }
  .headline_courses{
    font-size: 27px;
    color: #676767;
}
.title_course{
    color: rgb(17, 18, 18);
    width: 313px;
    font-size: 18px;
    text-align: left;
    position: relative;
    margin: auto;
}
.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 333px;
    cursor: pointer;
    border-radius: 12px;
  }
  .time{
    padding-left:160px;
    top: -20px;
    font-weight: 700;
    
      }
  @media only screen and (max-width: 666px)  {
    .card{
      width: 100%;
    }
    .title_course{
      width: 100%;
    }
    .time {
      padding-left: 120px;
    }
    
  }
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .container {
    padding: 2px 16px;
  }
.card a{
  all: unset;
}
  .card img{
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

  }

 
  .topContainer{
  }

 