.product_description{
    text-align: left;
    padding: 20px;
}
.titulo{
    color: #fefefe;
    padding-right: 3px;
    margin-right: 33px;

}
.divcontent > ul, .divcontent > li{
color: #000 !important;
}
.divcontent{
color: #000 !important;
}
.pinterestSave, .pineterestSave:visited, .pineterestSave:active, .pineterestSave:link, .pineterestSave:hover{
    color: black;
    font-size: 33px;
    background-color:#d50012;
    color: aliceblue;
    border: 1px salmon;
    padding: 6px;
    padding-top: 3px;
    padding-bottom: 10px;
    margin-top: 24px;
    text-decoration: none;
    border-radius: 9px;
    opacity: .88 !important;
    transition: 0.3s;
  
}
.pinterestSave:hover{
    opacity: 1;

}
.pinImg{
    margin-top: 9px !important;
position: absolute;
}

.pinText{
    margin-left: 39px;
    margin-top: 5px;
    margin-right: 10px;
    font-size: 27px;
}


.container_article {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  border: 1px solid #ddd;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.footerSocial{
    z-index: 99999999999999;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 65px;
    width: 100%;
    background-color: black;
    opacity: .87;
    color: white;
    text-align: center;
}
.label{
    background-color: black;
    color: aliceblue;
    padding: 6px;
    margin: 6px;
    margin-bottom: 12px !important;
    height: 30px !important;
}
.label{
    background-color: pink;
    color: black;
    font-weight: bold;
    padding: 4px;
    text-transform: uppercase;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: small;
  
}
.divcontent{
    width: 100%;
    text-align: left;
    margin-bottom: 22px;
    padding-bottom: 22px;
    font-size: 23px !important;
}

#labels{
}

.imageArticle{
    width: 90%;
    padding: 22px;
}

.grid-container>div>a:link, a:active, a:visited {
    color: lightblue;
}
main{
    padding-right: 33px;
}
.img-fluid{
    width: 70%;
}
@media only screen and (max-width: 600px) {
   .img-fluid{
padding-left: 10px; 
width: 100%;
}
.video{
    width: 100% !important;
}
}

.linkAd:visited{
    color: #000;
    
}

/* MODAL FOR EMAIL AND PUSH SUBSCRIPTION*/
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;

  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 333px;
    align-self: start;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .container {
    padding: 2px 16px;
  }
  .h4inline{
  margin-top: -244px;
  margin-left: 244px;
  }
  .p4inline{
  
  margin-left: 244px;
  }
  .b4inline{
  padding: 4px;
  margin-left: 244px;
  color:green;
  
  }
  .linkToPost{
  text-decoration: none;
  color: black!important;
  }

  .imgArtCard{
    left: 0 !important;
    align-items:first baseline;
  }

  .tarjeta {
    max-width: 65vw;
    margin: 48px auto;
    display: block;
    border-radius: 10px;
    border: 1px solid black;
    overflow: hidden;
  }
  .contenido {
    display: flex;
  }
  @media (max-width: 600px){
    .contenido{
    flex-direction: column;
    padding: 10px !important;
    }
    .card{
      width: 90%
    }
    .imgSkillShare{
        width: 100%;
    }
    .tarjeta {
     max-width: 95vw;
    }
    .artrecImg{
    width: 100% !important;
    height: auto !important;
    }
    .ladoIzq{
        margin-right: 100px !important;

    }
    .btnRec{
        margin-right: 85px !important;

    }
    .pRec{
        margin-right: 85px !important;
        text-align: right;

    }
  }
  
  .ladoIzq, .ladoDer {
    width: 100%;
  }
  .ladoDer img {
    width: 100%;
    height: 100%;
    border-style: none;
  }
  .ladoDer{
    background-color: #000;
  }
  .ladoIzq {
    background: black;
    color: white;
    padding: 48px
  }
  .btnRec {
    display: inline-block;
    background: white;
    margin-right: 4px;
    padding: 24px;
    border-radius: 5px;
    margin-top: 18px;

  }
  a.btnRec{
     text-decoration: none;
  }
  a.btnRec:first-of-type {
     background: #5252b4;
     color: white;
  }
  a.btnRec:hover{
     opacity: 0.8;
  }
  .cuerpo{

  }
.pRec{
    padding: 5px !important;
}
.book_recommended h1{
    color: #000 !important;
}

  .postText{
    color: #111 !important;
  }
  p{
    color: #111 !important;
  }
  h2, h1{
    color: #666;
  }
  .pRec{
    color: #fefefe !important;
  }
  #previousB{
    cursor: pointer;
    position: absolute;
    margin-left: -130px;
    margin-top: -20px;
    width: 120px;
    height: 30px;
    color: #f1f1f1;
    font-size: 23px !important;
text-decoration: none;
z-index: 100000000;
    background-color: #333 !important;
  }
  #nextB{
    color: #111;
font-size: 33px !important;
    cursor: pointer;
    position: absolute;
    margin-right: -160px;
    margin-top: -20px;
    width: 120px;
    height: 30px;
    color: #f1f1f1;
    font-size: 23px !important;
text-decoration: none;
z-index: 100000000;
    background-color: #333 !important;

  }
