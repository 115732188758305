.btnreset{
    height: 53px;
    padding: 12px !important;
     width: 300px;  text-align: left;
     cursor: pointer;
     background-color: #FF6D60;
   
}
.btnreset:hover{
    background-color: #000000;
    transition: ease-in;
    transition-duration: 2;
}

.info{
    color: #001;
}

#reset h2{
    color: #001;
}