#whitepaper{
    padding: 10px;
    color: black !important;
    align-content: start;
    text-align: left
}
#whitepaper li{
    all: unset !important;
    list-style-type: none;

}